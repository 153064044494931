import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import {
  addTrade,
  updateTrade,
} from "../../common/services/marketdata/tradeService";
import { getOrganizations } from "../../common/services/auth/organizationService";
import { getTermsByDate } from "../../common/services/markets/termService";
import Form from "../../common/components/form/form";
import Loading from "../../common/components/loading/loading";
import { withTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";
import { formatDate } from "../../common/services/utilities";

class TradeInput extends Form {
  state = {
    organizations: [],
    terms: [],
    data: {
      tradeId: null,
      organizationId: null,
      marketId: null,
      termId: null,
      priceBasisId: null,
      currencyId: null,
      uomId: null,
      tradeDate: null,
      qualityAttributeValues: [],
      active: null,
      buyerOrganizationId: [""],
      sellerOrganizationId: [""],
      price: "",
      volume: "",
      eventId: null,
    },
    createdDate: null,
    updatedDate: null,
    errors: {},
    isLoading: true,
    isNew: null,
    collapseOpen: false,
    joiAllowUnknown: true,
  };

  schema = {
    tradeId: Joi.string().required(),
    marketId: Joi.string().required(),
    organizationId: Joi.string().required(),
    termId: Joi.string().required(),
    priceBasisId: Joi.string().required(),
    currencyId: Joi.string().required(),
    uomId: Joi.string().required(),
    tradeDate: Joi.date().required(),
    qualityAttributeValues: Joi.array().items(),
    buyerOrganizationId: //Joi.array().items(Joi.alternatives().try(Joi.string(),Joi.object())).min(1).max(1)
      Joi.alternatives().try(
        Joi.array().items(Joi.string().allow('')).min(1).max(1), //Remove .allow('') to make mandatory
        Joi.array().items(Joi.object()).min(0).max(1) // min(0).max(1) for optional min(1).max(1) for mandatory.
      )
      .label(this.props.t("BuyerOrganization")),
    sellerOrganizationId: 
      Joi.alternatives().try(
        Joi.array().items(Joi.string().allow('')).min(1).max(1), //Remove .allow('') to make mandatory
        Joi.array().items(Joi.object()).min(0).max(1) // min(0).max(1) for optional min(1).max(1) for mandatory.
      )
      .label(this.props.t("SellerOrganization")),
    //Precision is not working, need to come back to this.
    price: Joi.number()
      .required()
      .precision(this.props.pricePrecision ?? 2)
      .label(this.props.t("Price")),
    volume: Joi.number()
      .required()
      .precision(this.props.volumePrecision ?? 2)
      .label(this.props.t("Volume")),
    close: Joi.number().label(this.props.t("Close")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string().required(),
  };

  async componentDidMount() {
    //try{
    const {
      product,
      trade,
      isNew,
      allowEdit,
    } = this.props;

    this.setState({ isNew: isNew, collapseOpen: !allowEdit });

    const organizations = (await getOrganizations()).filter(x => x.active || x.organizationId === trade.buyerOrganizationId || x.organizationId === trade.sellerOrganizationId);
 
    organizations.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(trade.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(trade.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    //Extend the Joi schema to include attributes
    this.schema = {
      ...this.schema,
      ...product.qualityAttributes
        .filter((a) => a.isPriceDriver)
        .reduce((acc, attribute) => {
          acc[attribute.qualityAttributeId] = Joi.string()
            .required()
            .label(attribute.qualityAttributeName);
          return acc;
        }, {}),
      ...product.qualityAttributes
        .filter((a) => !a.isPriceDriver)
        .reduce((acc, attribute) => {
          acc[attribute.qualityAttributeId] = Joi.string()
            .allow("", null)
            .label(attribute.qualityAttributeName);
          return acc;
        }, {}),
    };

    //Override the IDs with the objects for the typeahead
    const expandedTrade = 
    {...trade,
    ...{ buyerOrganizationId: organizations.find(x=> x.organizationId === trade.buyerOrganizationId)},
    ...{ sellerOrganizationId: organizations.find(x=> x.organizationId === trade.sellerOrganizationId)}
    };

    this.setState({
      data: this.mapToViewModel(expandedTrade),
      organizations: organizations,
      isLoading: false,
    });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  async componentDidUpdate(_, prevState) {
    const { tradeDate, priceBasisId } = this.state.data;
    const { termScheduleId, marketAttributes } = this.props;

    if (tradeDate !== prevState.data.tradeDate) {
      this.updateTerms(termScheduleId, tradeDate);
    }
    if (priceBasisId !== prevState.data.priceBasisId) {
      const data = { ...this.state.data };
      //draw the currency and UOM from the marketAttributes
      data.uomId =
        marketAttributes[
          marketAttributes.findIndex((x) => x.priceBasisId === priceBasisId)
        ].uomId;
      data.currencyId =
        marketAttributes[
          marketAttributes.findIndex((x) => x.priceBasisId === priceBasisId)
        ].currencyId;

      this.setState({ data: data });
    }
  }

  async updateTerms(termScheduleId, tradeDate) {
    const terms = await getTermsByDate(termScheduleId, tradeDate);
    this.setState({
      terms: terms.sort((a, b) =>
        new Date(a.endDate) > new Date(b.endDate) ? 1 : -1
      ),
    });
  }

  mapToViewModel(trade) {
    const { marketId, priceBasisId, currencyId, uomId } =
      this.props;

    return {
      ...{
        marketId: marketId,
        priceBasisId: priceBasisId,
        currencyId: currencyId,
        uomId: uomId,
      },
      ...trade,
      ...{ buyerOrganizationId: [trade.buyerOrganizationId ?? ""]},
      ...{ sellerOrganizationId: [trade.sellerOrganizationId ?? ""]},
      ...{ tradeDate: formatDate(trade.tradeDate) }, //Override so the date control works properly.
      ...{price: trade.price ?? ""}, //Override null prices so React doesn't compain about controlled inputs.
      ...{volume: trade.volume ?? ""}, //Override null prices so React doesn't compain about controlled inputs.
      ...(trade.qualityAttributeValues ?? []).reduce((acc, attribute) => {
        acc[attribute.qualityAttributeId] = attribute.qualityAttributeValueId;
        return acc;
      }, {}),
    };
  }

  doSubmit = async () => {
    const { t, product, marketAttributes } = this.props;
    const { isNew, terms, organizations } = this.state;
    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      //Generate the AttributeValue fields
      const transformedData = {
        ...data,
        ...{
          buyerOrganizationId:
            data.buyerOrganizationId[0] === "" ? null : data.buyerOrganizationId[0].organizationId, //Fix these so they bind properly to the API model.
          sellerOrganizationId:
            data.sellerOrganizationId[0] === "" ? null : data.sellerOrganizationId[0].organizationId,
        },
        instrumentQualityAttributeValues: product.qualityAttributes
          .filter((a) => a.isPriceDriver)
          .map((attributeValue) => {
            const index = product.qualityAttributeValues.findIndex(
              (u) =>
                u.qualityAttributeValueId ===
                data[attributeValue.qualityAttributeId]
            );
            return {
              qualityAttributeId: attributeValue.qualityAttributeId,
              qualityAttributeValueId: data[attributeValue.qualityAttributeId],
              value: product.qualityAttributeValues[index].value,
              active: product.qualityAttributeValues[index].active,
            };
          }),
        tradeQualityAttributeValues: product.qualityAttributes
          .filter((a) => !a.isPriceDriver)
          .map((attributeValue) => {
            const index = product.qualityAttributeValues.findIndex(
              (u) =>
                u.qualityAttributeValueId ===
                data[attributeValue.qualityAttributeId]
            );
            if (index === -1) return null; //optional field not set
            return {
              qualityAttributeId: attributeValue.qualityAttributeId,
              qualityAttributeValueId: data[attributeValue.qualityAttributeId],
              value: product.qualityAttributeValues[index].value,
              active: product.qualityAttributeValues[index].active,
            };
          })
          .filter((item) => item !== null), // filter out null values from unset optional fields
      };

      var response;
      if (!isNew) {
        response = await updateTrade(transformedData);
        toast.success(t("TradeUpdated"));
      } else {
        response = await addTrade(transformedData);
        this.setState({ isNew: false });
        toast.success(t("TradeAdded"));
      }

      const termIndex = terms.findIndex((u) => u.termId === response.termId);
      const buyerOrganizationIndex = organizations.findIndex(
        (u) => u.organizationId === response.buyerOrganizationId
      );
      const sellerOrganizationIndex = organizations.findIndex(
        (u) => u.organizationId === response.sellerOrganizationId
      );
      const priceBasisIndex = marketAttributes.findIndex(
        (u) => u.priceBasisId === response.priceBasisId
      );

      const newTrade = {
        ...response,
        ...{
          termName: termIndex > -1 && terms[termIndex].name,
          buyerOrganizationName:
            buyerOrganizationIndex > -1 &&
            organizations[buyerOrganizationIndex].name,
          sellerOrganizationName:
            sellerOrganizationIndex > -1 &&
            organizations[sellerOrganizationIndex].name,
          priceBasisName:
            priceBasisIndex > -1 &&
            marketAttributes[priceBasisIndex].priceBasisName,
        },
      };

      // //Call back to the cell list to allow it to close the modal and update it's display value.
      this.props.submitCallbackFunction(newTrade);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;              
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Price = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const {
      /* organization, */ isLoading,
      /*  isNew, */ createdDate,
      updatedDate,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, product, marketAttributes, allowEdit } = this.props;
    const { collapseOpen } = this.state;

    var date = new Date();
    const endDate = date.toLocaleDateString("fr-CA");

    const priceBasisIndex = marketAttributes.findIndex(
      (u) => u.priceBasisId === this.state.data.priceBasisId
    );
    const uomName =
      priceBasisIndex > -1 && marketAttributes[priceBasisIndex].uomName;
    const currencyName =
      priceBasisIndex > -1 && marketAttributes[priceBasisIndex].currencyName;

    return (
      <div>
        <form
          
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              <div className="row">
                <div className="col">
                  {this.renderSelect(
                    "priceBasisId",
                    t("PriceBasis"),
                    marketAttributes,
                    "priceBasisId",
                    "priceBasisName"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderDateInput(
                    "tradeDate",
                    t("TradeDate"),
                    null,
                    endDate
                  )}
                </div>
                <div className="col">
                  {this.renderSelect(
                    "termId",
                    t("Term"),
                    this.state.terms,
                    "termId"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderInput(
                    "price",
                    t("Price") + " (" + currencyName + ")"
                  )}
                </div>
                <div className="col">
                  {this.renderInput(
                    "volume",
                    t("Volume") + " (" + uomName + ")"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderTypeahead(
                    "buyerOrganizationId",
                    t("BuyerOrganization"),
                    this.state.organizations,
                    false, //not disabled
                    "organizationId"
                  )}                        
                </div>
                <div className="col">
                  {this.renderTypeahead(
                    "sellerOrganizationId",
                    t("SellerOrganization"),
                    this.state.organizations,
                    false, //not disabled
                    "organizationId"
                  )}      
                </div>
              </div>
              <div>
                {product.qualityAttributes &&
                  product.qualityAttributes
                    .filter((a) => a.isPriceDriver)
                    .map((attribute) => (
                      <div className="row" key={attribute.qualityAttributeId}>
                        <div className="col">
                          {this.renderSelect(
                            attribute.qualityAttributeId,
                            attribute.qualityAttributeName,
                            product.qualityAttributeValues.filter(
                              (v) =>
                                v.qualityAttributeId ===
                                attribute.qualityAttributeId
                                && (v.active || v.qualityAttributeValueId === attribute.qualityAttributeId)
                            ),
                            "qualityAttributeValueId",
                            "value"
                          )}
                        </div>
                      </div>
                    ))}
              </div>
              { product.qualityAttributes.filter((a) => !a.isPriceDriver).length > 0 &&
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="btn btn-sm btn-secondary float-sm-right"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ collapseOpen: !collapseOpen });
                    }}
                  >
                    {collapseOpen ? "-" : "+"}
                  </button>
                </div>
              }
              <Collapse in={collapseOpen}>
                <div>
                  {product.qualityAttributes &&
                    product.qualityAttributes
                      .filter((a) => !a.isPriceDriver)
                      .map((attribute) => (
                        <div className="row" key={attribute.qualityAttributeId}>
                          <div className="col">
                            {this.renderSelect(
                              attribute.qualityAttributeId,
                              attribute.qualityAttributeName,
                              product.qualityAttributeValues.filter(
                                (v) =>
                                  v.qualityAttributeId ===
                                  attribute.qualityAttributeId
                                  && (v.active || v.qualityAttributeValueId === attribute.qualityAttributeId)
                              ),
                              "qualityAttributeValueId",
                              "value"
                            )}
                          </div>
                        </div>
                      ))}
                </div>
              </Collapse>
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(TradeInput);
