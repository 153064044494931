import React, { Component } from "react";
import Loading from "../../common/components/loading/loading";
import { withTranslation } from "react-i18next";
import { 
          getWindLongTermForecastByMarketDate
        , getSolarLongTermForecastByMarketDate
        , getPoolPriceByMarketDate
        , getInternalLoadByMarketDate
        , getSummary
      } from "../../common/services/marketdata/aesoService";
import {
          getSummaryReport,      
          getCalSettlementsByMarketDateRange
} from  "../../common/services/marketdata/ngxSettlementService";
import {
  getMarkets
} from  "../../common/services/markets/marketService";
import AesoSummaryTable from "./aesoSummaryTable";
import HourlyForecastLineGraph from "./hourlyForecastLineGraph";
import DailyForecastBarGraph from "./dailyForecastBarGraph";
import HourlyElectricityPricingAndLoadGraph from "./hourlyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadGraph from "./dailyElectricityPricingAndLoadGraph";
import DailyElectricityPricingAndLoadDeltaGraph from "./dailyElectricityPricingAndLoadDeltaGraph";
import SettlementsTable from "./settlementsTable";
import HistoricalSettlementsGraph from "./historicalSettlementsGraph";
import Input from "../../common/components/form/input";

class AlbertaPowerReport extends Component {
  state = {
    longTermWindForecast: [],
    longTermSolarForecast: [],
    poolPriceInternalLoadDataMerged: [],
    powerSettles: [],
    calPowerSettles: [],
    poolPrices: [],
    internalLoad: [],    
    marketDate: "",
    isLoading: true,
    reloading: false,
  };

  async componentDidMount() {
    const marketDate = new Date().toLocaleDateString("fr-CA");

    await this.loadData(marketDate);
  }

  async loadData(marketDate)
  {

    var fromDate = new Date(marketDate);
    fromDate.setDate(fromDate.getDate() - 30);

    var toDate = new Date(marketDate);
    toDate.setDate(toDate.getDate() - 0);

    const markets = await getMarkets();
    const aesoMarket = markets.find(x => x.name ==='AESO');;
    const albertaGasMarket = markets.find(x=> x.name ==='AECO-C');

    const fromDateParam = fromDate.toLocaleDateString("fr-CA", {timeZone: "UTC"});
    const toDateParam = toDate.toLocaleDateString("fr-CA", {timeZone: "UTC"});
    
    const longTermWindForecast = await getWindLongTermForecastByMarketDate(marketDate);
    const longTermSolarForecast = await getSolarLongTermForecastByMarketDate(marketDate);
    
    const poolPrices = await getPoolPriceByMarketDate(fromDateParam, toDateParam);
    const internalLoad = await getInternalLoadByMarketDate(fromDateParam, toDateParam);
    const poolPriceInternalLoadDataMerged = await this.mergePoolPriceInternalLoad(poolPrices, internalLoad);

    const powerSettlesRaw = aesoMarket ? (await getSummaryReport(aesoMarket.marketId,marketDate)) : [];
    const powerSettles = await this.sortAndSliceSettles(powerSettlesRaw)

    const gasSettlesRaw = albertaGasMarket ? (await getSummaryReport(albertaGasMarket.marketId,marketDate)) : [];
    const gasSettles = await this.sortAndSliceSettles(gasSettlesRaw)

    const calSettlesFromDate = new Date(marketDate)
    calSettlesFromDate.setFullYear(calSettlesFromDate.getFullYear() - 3);

    const calPowerSettlesRaw = aesoMarket ? (await getCalSettlementsByMarketDateRange(aesoMarket.marketId, calSettlesFromDate.toLocaleDateString("fr-CA"), marketDate)) : [];

    const fromTerm = (new Date(marketDate)).getFullYear();
    const toTerm = fromTerm + 4;
    const calPowerSettles = calPowerSettlesRaw.filter(x => x.termName >= 'Cal '+fromTerm && x.termName <= 'Cal '+toTerm);

    const priorDay = (new Date(marketDate));
    priorDay.setDate( priorDay.getDate() - 1 );
    
    const aesoSummaryCurrent = await getSummary(priorDay.toLocaleDateString("fr-CA", {timeZone: "UTC"}));

    const priorYear = (new Date(priorDay));
    priorYear.setFullYear( priorYear.getFullYear() - 1 );
    const aesoSummaryPriorYear = await getSummary(priorYear.toLocaleDateString("fr-CA", {timeZone: "UTC"}));
    //const gasSettlesPriorYearRaw = albertaGasMarket ? (await getSummaryReport(albertaGasMarket.marketId,priorYear.toLocaleDateString("fr-CA", {timeZone: "UTC"}))) : [];
    //const gasSettlesPriorYear = await this.sortAndSliceSettles(gasSettlesPriorYearRaw)

    const aesoSummaryData = await this.generateAesoSummary(aesoSummaryCurrent, aesoSummaryPriorYear, longTermWindForecast, longTermSolarForecast, marketDate);

    this.setState(
      {
        longTermWindForecast: longTermWindForecast,
        longTermSolarForecast: longTermSolarForecast,
        poolPrices: poolPrices,
        internalLoad: internalLoad,
        powerSettles: powerSettles,
        gasSettles: gasSettles,
        calPowerSettles: calPowerSettles,
        aesoSummaryData: aesoSummaryData,
        poolPriceInternalLoadDataMerged: poolPriceInternalLoadDataMerged,
        isLoading: false,
        reloading: false,
        marketDate: marketDate,
      }
    );
  }

  async sortAndSliceSettles(settles)
  {
    const calSettles = settles.filter(x => x.termName.indexOf('Cal') >= 0);
    calSettles.sort((a,b) => a.termName - b.termName);

    const monthlySettles = settles.filter(x => x.termName.indexOf('Cal') < 0 && x.termName !== 'Prior Day' && x.termName !== 'Same Day' && x.termName !== 'Day Ahead' && x.termName.indexOf('D+') < 0);
    monthlySettles.sort((a,b) => new Date(a.beginDate) - new Date(b.beginDate));

    const dailySettles = settles.filter(x => x.termName === 'Prior Day' || x.termName === 'Same Day' || x.termName === 'Day Ahead'/*  || x.termName.indexOf('D+') >= 0 */);
    dailySettles.sort((a,b) => new Date(a.beginDate) - new Date(b.beginDate));

    const processedSettles = [...dailySettles, ...monthlySettles.slice(0,12), ...calSettles.slice(0,5)];

    return processedSettles;
  }

  async mergePoolPriceInternalLoad(poolPrices, internalLoad)
  {
    function sameDay(date1, date2) {    
      const d1 = new Date (date1)
      const d2 = new Date(date2)
      return d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getFullYear() === d2.getFullYear()        
        ;
    }

    // Merge the two datasets based on begin_datetime_mpt
    const poolPriceInternalLoadDataMerged = poolPrices.map(poolItem => {
      const matchingInternalLoadItem = internalLoad.find(internalItem => sameDay(internalItem.marketDate, poolItem.marketDate) && internalItem.beginDateTimeMpt === poolItem.beginDateTimeMpt);
      return {
        beginDateTimeMpt: poolItem.beginDateTimeMpt,
        beginDateTimeUtc: poolItem.beginDateTimeUtc,
        albertaInternalLoad: (matchingInternalLoadItem ? matchingInternalLoadItem.albertaInternalLoad : null),
        forecastAlbertaInternalLoad: (matchingInternalLoadItem ? matchingInternalLoadItem.forecastAlbertaInternalLoad : null),
        poolPrice: poolItem.poolPrice,
        forecastPoolPrice: poolItem.forecastPoolPrice,
        rolling30DayAvg: poolItem.rolling30DayAvg,
        marketDate: poolItem.marketDate
      };
      });

      return(poolPriceInternalLoadDataMerged);
  };

  async generateAesoSummary(aesoSummaryCurrent, aesoSummaryPriorYear, longTermWindForecast, longTermSolarForecast, marketDate)
  {
    const aesoSummaryCurrentExtended = 
      aesoSummaryCurrent.map(data => (
        {
            sortOrder: data.item === "Summary" ? 0 : data.item === "MTD Avg" ? 1 : data.item === "YTD Avg" ? 2 : 999,
            ...data,
            pricePrecision: 2,
            currencySymbol: "$",
            uomSymbol: "",
        }
    ));

    const aesoLoadExtended = 
      aesoSummaryCurrent
      .filter(x => x.item === "Summary")
      .map(data => (
        {
            sortOrder: data.item === "Summary" ? 3 : 999,
            item: "Avg Load",
            beginDateTime: data.beginDateTime,
            numericAverage: data.averageLoad,
            numericAverageYesterday: data.averageLoadYesterday,
            onPeak7x16Average: data.onPeak7x16AverageLoad,
            onPeak7x16AverageYesterday: data.onPeak7x16AverageLoadYesterday,
            offPeak7x16Average: data.offPeak7x16AverageLoad,
            offPeak7x16AverageYesterday: data.offPeak7x16AverageLoadYesterday,
            pricePrecision: 0,
            currencySymbol: "",
            uomSymbol: "MW"            
        }
    ));

    const aesoSummaryPriorYearExtended = 
    aesoSummaryPriorYear
      .filter(x => x.item !== "MTD Avg")
      .map(data => (
        {
            sortOrder: data.item === "MTD Avg" ? 4 : data.item === "YTD Avg" ? 5 : 999,
            ...data,
            item: data.item === "Summary" ? "Prior Year" : data.item === "YTD Avg" ? "YTD Prior Year" : "Unknown",
            pricePrecision: 2,
            currencySymbol: "$",
            uomSymbol: "",
        }
    ));

    const currentDate = new Date(marketDate)
    currentDate.setDate(currentDate.getDate() - 1);
    currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset())

    const priorDate = new Date(currentDate)
    priorDate.setDate(priorDate.getDate() - 1);
    priorDate.setMinutes(priorDate.getMinutes() + priorDate.getTimezoneOffset())

    const windToday =  longTermWindForecast
      .filter(x => new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA",  {timeZone: "UTC"}) === currentDate.toLocaleDateString("fr-CA"));
    const windTodayOnPeak = windToday.filter(x => (new Date(x.forecastTransactionDate)).getHours() >= 8 && (new Date(x.forecastTransactionDate)).getHours() <=22)
    const windTodayOffPeak = windToday.filter(x => (new Date(x.forecastTransactionDate)).getHours() < 8 || (new Date(x.forecastTransactionDate)).getHours() > 22)

    const windYesterday =  longTermWindForecast
      .filter(x => new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA",  {timeZone: "UTC"}) === priorDate.toLocaleDateString("fr-CA"));
      const windYesterdayOnPeak = windYesterday.filter(x => (new Date(x.forecastTransactionDate)).getHours() >= 8 && (new Date(x.forecastTransactionDate)).getHours() <=22)
      const windYesterdayOffPeak = windYesterday.filter(x => (new Date(x.forecastTransactionDate)).getHours() < 8 || (new Date(x.forecastTransactionDate)).getHours() > 22)
      
    const wind = 
    [
      {
        sortOrder: 6,
        item: "Wind",
        beginDateTime: currentDate,
        numericAverage: windToday.length > 0 ? windToday.reduce((a, b) => ({actual: a.actual + b.actual})).actual/windToday.length : null,
        numericAverageYesterday: windYesterday.length > 0 ? windYesterday.reduce((a, b) => ({actual: a.actual + b.actual})).actual/windToday.length : null,
        onPeak7x16Average: windTodayOnPeak.length > 0 ? windTodayOnPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/windTodayOnPeak.length : null,
        onPeak7x16AverageYesterday: windYesterdayOnPeak.length > 0 ? windYesterdayOnPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/windYesterdayOnPeak.length : null,
        offPeak7x16Average: windTodayOffPeak.length > 0 ? windTodayOffPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/windTodayOffPeak.length : null,
        offPeak7x16AverageYesterday: windYesterdayOffPeak.length > 0 ? windYesterdayOffPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/windYesterdayOffPeak.length : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW"            
      }
    ]

    const solarToday =  longTermSolarForecast
    .filter(x => new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA",  {timeZone: "UTC"}) === currentDate.toLocaleDateString("fr-CA"));
    const solarTodayOnPeak = solarToday.filter(x => (new Date(x.forecastTransactionDate)).getHours() >= 8 && (new Date(x.forecastTransactionDate)).getHours() <=22)
    const solarTodayOffPeak = solarToday.filter(x => (new Date(x.forecastTransactionDate)).getHours() < 8 || (new Date(x.forecastTransactionDate)).getHours() > 22)

    const solarYesterday =  longTermSolarForecast
      .filter(x => new Date(x.forecastTransactionDate).toLocaleDateString("fr-CA",  {timeZone: "UTC"}) === priorDate.toLocaleDateString("fr-CA"));
      const solarYesterdayOnPeak = solarYesterday.filter(x => (new Date(x.forecastTransactionDate)).getHours() >= 8 && (new Date(x.forecastTransactionDate)).getHours() <=22)
      const solarYesterdayOffPeak = solarYesterday.filter(x => (new Date(x.forecastTransactionDate)).getHours() < 8 || (new Date(x.forecastTransactionDate)).getHours() > 22)
      
    const solar = 
    [
      {
        sortOrder: 7,
        item: "Solar",
        beginDateTime: currentDate,
        numericAverage: solarToday.length > 0 ? solarToday.reduce((a, b) => ({actual: a.actual + b.actual})).actual/solarToday.length : null,
        numericAverageYesterday: solarYesterday.length > 0 ? solarYesterday.reduce((a, b) => ({actual: a.actual + b.actual})).actual/solarToday.length : null,
        onPeak7x16Average: solarTodayOnPeak.length > 0 ? solarTodayOnPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/solarTodayOnPeak.length : null,
        onPeak7x16AverageYesterday: solarYesterdayOnPeak.length > 0 ? solarYesterdayOnPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/solarYesterdayOnPeak.length : null,
        offPeak7x16Average: solarTodayOffPeak.length > 0 ? solarTodayOffPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/solarTodayOffPeak.length : null,
        offPeak7x16AverageYesterday: solarYesterdayOffPeak.length > 0 ? solarYesterdayOffPeak.reduce((a, b) => ({actual: a.actual + b.actual})).actual/solarYesterdayOffPeak.length : null,
        pricePrecision: 0,
        currencySymbol: "",
        uomSymbol: "MW"            
      }
    ]

    const aesoSummary = 
      [
        ...aesoSummaryCurrentExtended,
        ...aesoLoadExtended,
        ...aesoSummaryPriorYearExtended,
        ...wind,
        ...solar
      ]

    return aesoSummary
  }

  handleDateChange = (marketDate) => {
    this.setState({marketDate: marketDate, reloading: true});

    (async () => {
      await this.loadData(marketDate);
    })();
  };

  render() {
    const {
      longTermWindForecast,
      longTermSolarForecast,
      powerSettles,
      gasSettles,
      calPowerSettles,
      poolPriceInternalLoadDataMerged,
      aesoSummaryData,
      isLoading,
      //reloading,
      marketDate,
    } = this.state;

    const {
      t,
    } = this.props;

    const minDate = '2024-01-01';
    const maxDate = new Date();

    const priorDate = new Date(marketDate)
    priorDate.setDate(priorDate.getDate() - 1);
    priorDate.setMinutes(priorDate.getMinutes() + priorDate.getTimezoneOffset())

    const priorPriceInternalLoadData = poolPriceInternalLoadDataMerged.filter(x => new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA",  {timeZone: "UTC"}) === priorDate.toLocaleDateString("fr-CA"))

    const historicalPriceInternalLoadDataMerged = poolPriceInternalLoadDataMerged
      .filter(x => 
       new Date(x.beginDateTimeMpt).toLocaleDateString("fr-CA",  {timeZone: "UTC"}) <= priorDate.toLocaleDateString("fr-CA")
    )

    if (isLoading) return <Loading />;

    return (
      <div className="container-fluid" style={{height:"100%"}}>
        <div className="row" style={{height:"5%"}}>
          <div className="col-md-12 d-flex align-items-center justify-content-center"><h1>{t("AlbertaEnergyDaily")}</h1></div>
        </div>        
        <div className="row" style={{height:"8%"}}>
        <div className="col-md-2">
              <Input                                       
                  type="date"
                  min={minDate} 
                  max={maxDate} 
                  onKeyDown={(e) => e.preventDefault()}
                  // onFocus={(e) => e.blur()}
                  onChange={e => this.handleDateChange(e.currentTarget.value)}
                  value = {marketDate}
                  label = {t("MarketDate")}
              />                
            </div>  
        </div>              
        <div className="row" style={{overflowY:"auto", overflowX:"hidden", height:"87%"}}>
          <div className="col">
            <div className="row">
              <div className="col-md-8 tile">
                AESO Summary
                <AesoSummaryTable summaryData={aesoSummaryData} currencySymbol={'$'} pricePrecision={2}/>            
              </div>              
              <div className="col-md-4 tile">
                AB Natural Gas Storage
              </div>              
            </div>
            <div className="row">
              <div className="col-md-4 tile" style={{minHeight:"300px"}}>
                AESO Generation Statuses
              </div>              
              <div className="col-md-8 tile" style={{minHeight:"300px"}}>
                Alberta Retail Pricing Comparison
              </div>              
            </div>    
            <div className="row">
              <div className="col-md-4 tile" style={{minHeight:"300px"}}>
                AESO Average 7X24 Price and Load Last 30 days
                <DailyElectricityPricingAndLoadGraph data={historicalPriceInternalLoadDataMerged}/>                 
              </div>              
              <div className="col-md-4 tile" style={{minHeight:"300px"}}>
                AESO Average 7X24 Price and Load Delta Last 30 days
                <DailyElectricityPricingAndLoadDeltaGraph data={historicalPriceInternalLoadDataMerged}/>      
              </div>              
              <div className="col-md-4 tile" style={{minHeight:"300px"}}>
                AESO Alberta Average Hourly Electricity Pricing: {priorDate.toUTCString().substring(0,17)}
                <HourlyElectricityPricingAndLoadGraph data={priorPriceInternalLoadData}/>            
              </div>                        
            </div>   
            <div className="row">
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                7X24 Alberta Forward Power CAD$/MWh (The Ice)
                <SettlementsTable settlements={powerSettles} currencySymbol={'$'} pricePrecision={2}/>            
              </div>              
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                Natural Gas Prices CAD$/GJ (The Ice)
                <SettlementsTable settlements={gasSettles} currencySymbol={'$'} pricePrecision={2}/>            
              </div>              
            </div> 
            <div className="row">
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                Alberta Forward Calendar Pricing
                <HistoricalSettlementsGraph data={calPowerSettles}/>
              </div>              
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                AESO Average 7X24 Price and Load 
              </div>              
            </div> 
            <div className="row">
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                AESO 7X24 Weighted Average Prices 2008 to 2024
              </div>              
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                AESO Historical Monthly
              </div>              
            </div> 
            <div className="row">
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                Aeso 7 Day Hourly Wind Forecast
                <HourlyForecastLineGraph data={longTermWindForecast}/>
              </div>              
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                Aeso 7 Day Hourly Solar Forecast
                <HourlyForecastLineGraph data={longTermSolarForecast}/>
              </div>              
            </div> 
            <div className="row">
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                Aeso 7 Day Wind Forecast
                <DailyForecastBarGraph data={longTermWindForecast}/>
              </div>              
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                Aeso 7 Day Solar Forecast
                <DailyForecastBarGraph data={longTermSolarForecast}/>
              </div>              
            </div> 
            <div className="row">
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                RRO Enmax vs AESO 2018 Through 2024
              </div>              
              <div className="col-md-6 tile" style={{minHeight:"300px"}}>
                AESO Monthly Outage Forecast
              </div>              
            </div> 
          </div>
        </div>                                                              
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaPowerReport);
